/* 封装axios用于发送请求 */
import axios from 'axios'

// 创建一个新的axios实例
const request = axios.create({
  // baseURL: 'http://interview-api-t.itheima.net/h5/',
  // baseURL: 'http://localhost:8891/api/',  //local-dev
  //baseURL: 'http://123.57.149.56:8891/api/',  //PROD
  baseURL: 'https://www.ebell.xyz:9891/api/',  //PROD
  timeout: 5000 // 超时5000ms
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default request
