<template>
  <div class="main-page">
    <van-nav-bar
        title="用户份额分配流水"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"/>

    <van-form>
      <van-field
          v-model="mobile"
          readonly
          name="mobile"
          label="手机号码:"
      />
      <van-field
          v-model="username"
          readonly
          name="username"
          label="用户姓名:"
      />
      <van-field
          v-model="balanceTxt"
          readonly
          name="balanceTxt"
          label="现有份额:"
      />
    </van-form>

    <van-cell-group  title="用户份额流水">
      <template v-for="(log,index) in logList">
        <div class="dataCellClass">
          <!-- <p>用户信息：{{username}} ({{mobile}})</p> -->
          <p>分配份额：{{log.balance}}</p>
          <p>分配时间：{{log.logTime}}</p>
        </div>
      </template>
    </van-cell-group>

  </div>
</template>

<script>

export default {
  name: 'login-page',
  data () {
    return {
      username: '',
      mobile: '',
      balanceTxt: '',
      logList: []
    }
  },

  created () {
    this.initData()
  },

  methods: {
    // 数据初始化：

    onClickLeft() {
      console.log('onClickLeft')
      this.$router.back()
    },
    // 初始化init 单独封装一个函数
    async initData () {
      this.userId = this.$route.params.userId
      let headers =  {
        'content-type': 'application/json',
        'Authorization': localStorage.getItem('token') || ''
      }
      this.$axios({ headers: headers, method: 'GET', url: '/crpt/user/'+this.userId}).then(res => {
        this.username = res.data.username
        this.mobile = res.data.mobile
        this.balanceTxt = res.data.balance
      })

      this.$axios({ headers: headers, method: 'GET', url: '/crpt/user/balance-log/'+this.userId}).then(res => {
        console.log('/crpt/user/balance-log  ==>>> res.data', res.data)
        this.logList = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.leftLink {
  color: #069;
  font-size: 12px;
  padding-left: 20px;
  float: left;
}
.rightLink {
  color: #069;
  font-size: 12px;
  padding-right: 20px;
  float: right;
}

.dataCellClass {
  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid
}
.buttonClass {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.custom {
  margin-right: 10px;
}
</style>
