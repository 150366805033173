<template>
  <div class="home-page">

    <van-nav-bar title="节点数权主页"/>
    <van-cell-group title="您的详情">
      <van-cell title="用户姓名" :value="username" />
      <van-cell title="手机号码" :value="mobile" />
      <van-cell v-if="role=='admin'" title="用户角色" value="管理员" />
      <van-cell v-if="role=='user'" title="用户份额" :value="balanceTxt" />
    </van-cell-group>

    <van-cell-group v-if="role=='user'" title="用户份额流水">
      <template v-for="(log,index) in logList">
        <div class="dataCellClass">
          <!-- <p>用户信息：{{username}} ({{mobile}})</p> -->
          <p>分配份额：{{log.balance}}</p>
          <p>分配时间：{{log.logTime}}</p>
        </div>
      </template>
    </van-cell-group>

    <van-cell-group v-if="role=='admin'" title="用户列表">
      <template v-for="(user,index) in userList">
        <div class="dataCellClass">
          <p>用户信息：{{user.username}} ({{user.mobile}})</p>
          <p>用户份额：{{user.balanceTxt}}</p>
          <div class="buttonClass" >
            <div class="custom">
            <van-button type="primary" size="small" plain round
                        @click="addBalance(user.id)">分配份额 </van-button>
            </div>
            <van-button type="primary" size="small" plain round
                        @click="balanceLog(user.id)">份额流水 </van-button>
          </div>
        </div>
      </template>
    </van-cell-group>

  </div>
</template>

<script>

export default {
  name: 'home-page',
  data () {
    return {
      role: 'user',
      userId: '',
      username: '',
      mobile: '',
      balanceTxt: '0',
      show: 'NO',
      userList: [],
      logList: []
    }
  },
  // 数据初始化：
  created () {
    console.log('>>>> created >>>>>')
    this.initData()
  },
  methods: {
    addBalance (userId) {
      this.$router.push({ path: '/balanceForm/'+userId }).catch(err =>{})
    },
    balanceLog (userId) {
      this.$router.push({ path: '/balanceLog/'+userId }).catch(err =>{})
    },
    // 初始化init 单独封装一个函数
    async initData () {
      let userId = localStorage.getItem('busiPersonId')
      this.userId = userId
      let headers =  {
        'content-type': 'application/json',
        'Authorization': localStorage.getItem('token') || ''
      }
      this.$axios({ headers: headers, method: 'GET', url: '/crpt/user/' + userId}).then(res => {
        console.log('cprt/user/currentDetail res=', res);
        this.balanceTxt = res.data.balanceTxt
        this.username = res.data.username
        this.mobile = res.data.mobile
        this.role = res.data.role

        if(this.role!='user') {
          this.$axios({ headers: headers, method: 'GET', url: '/crpt/user/page?role=admin&pageSize=1000'}).then(res => {
            console.log('cprt/user/list() res=', res.data.records);
            this.userList = res.data.records
            console.log('>>> this.userList=', this.userList)
            this.show = 'YES'
          })
        }
        if(this.role=='user') {
          this.$axios({headers: headers, method: 'GET', url: '/crpt/user/balance-log/' + userId}).then(res => {
            console.log('/crpt/user/balance-log  ==>>> res.data', res.data)
            this.logList = res.data
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.leftLink {
  color: #069;
  font-size: 12px;
  padding-left: 20px;
  float: left;
}
.rightLink {
  color: #069;
  font-size: 12px;
  padding-right: 20px;
  float: right;
}
.dataCellClass {
  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid
}
.buttonClass {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.custom {
  margin-right: 10px;
}
</style>
