<template>
  <div class="main-page">

    <van-nav-bar
        title="分配用户份额"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"/>

    <van-form @submit="onSubmit">
      <van-field
        v-model="mobile"
        readonly
        name="mobile"
        label="手机号码:"
      />
      <van-field
        v-model="username"
        readonly
        name="username"
        label="用户姓名:"
      />
      <van-field
          v-model="balanceTxt"
          readonly
          name="balanceTxt"
          label="现有份额:"
      />
      <van-field
          v-model="balance"
          name="balance"
          placeholder="请输入分配份额"
          label="分配份额:"
      />
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">确  定</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>

export default {
  name: 'login-page',
  data () {
    return {
      role: 'user',
      userId: null,
      username: '',
      mobile: '',
      balance: '',
      balanceTxt: '',
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.userId = this.$route.params.userId
      let headers =  {
        'content-type': 'application/json',
        'Authorization': localStorage.getItem('token') || ''
      }
      this.$axios({ headers: headers, method: 'GET', url: '/crpt/user/'+this.userId}).then(res => {
        console.log('cprt/user/currentDetail() res=', res);
        this.balanceTxt = res.data.balanceTxt
        this.username = res.data.username
        this.mobile = res.data.mobile
        this.role = res.data.role
      })
    },
    onClickLeft() {
      console.log('onClickLeft')
      this.$router.back()
    },
    onSubmit (values) {
      let headers =  {
        'content-type': 'application/json',
        'Authorization': localStorage.getItem('token') || ''
      }
      values.id = this.userId
      console.log('onSubmit = ', values)
      this.$axios({ headers:headers, method: 'POST', url: '/crpt/user/balance', data: values }).then(res => {
        console.log('crpt/user/balance() res=', res);
        this.$toast.success('为用户分配份额成功')
        this.$router.back();
      })
    }
  }
}
</script>

<style lang="less" scoped>
.leftLink {
  color: #069;
  font-size: 12px;
  padding-left: 20px;
  float: left;
}
.rightLink {
  color: #069;
  font-size: 12px;
  padding-right: 20px;
  float: right;
}
</style>
