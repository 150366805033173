<template>
  <div class="login-page">
    <van-nav-bar
      title="注册页面"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"/>

    <van-form @submit="onSubmit">
      <van-field
        v-model="mobile"
        name="mobile"
        label="手机号码"
        placeholder="请填写手机号码"
        :rules="[{ required: true, message: '请填写手机号码' }]"
      />
      <van-field
          v-model="username"
          name="username"
          label="用户姓名"
          placeholder="请填写用户姓名"
          :rules="[{ required: true, message: '请填写用户姓名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="用户密码"
        placeholder="请填写用户密码"
        :rules="[{ required: true, message: '请填写用户密码' }]"
      />
      <van-field
        v-model="validateCode"
        name="validateCode"
        label="验证码"
        placeholder="请填写验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
        use-button-slot
      >
        <van-button
            slot="button"
            size="small"
            @click="sendSms"
            :disabled="sendBtnDisabled"
            type="primary">
          {{sendBtnTxt}}
        </van-button>
      </van-field>

      <div style="margin: 16px">
        <van-button block type="primary" native-type="submit">确  定</van-button>
      </div>
    </van-form>

    <router-link class="link" to="/login">有账号，去登录</router-link>
  </div>
</template>

<script>
export default {
  name: 'register-page',
  data () {
    return {
      sendBtnDisabled: false,
      sendBtnTxt: '发送验证码',
      mobile: '',
      username: '',
      password: '',
      validateCode: '',
      sendTimes: 1,
    }
  },
  methods: {
    doCountDownAction(){
      let codeTime = 60;
      let time = setInterval(()=>{
        codeTime--;
        this.sendBtnTxt = codeTime+' 秒'
        this.sendBtnDisabled = true
        if(codeTime<1){
          clearInterval(time);
          codeTime = 0;
          this.sendBtnTxt = '再次发送'
          this.sendBtnDisabled = false
        }
      }, 1000);
    },
    sendSms(){
      console.log('sendSms() >>> buttonLabel = ', this.buttonLabel)
      this.sendBtnTxt = '开始倒计时'
      this.sendBtnDisabled = true
      this.doCountDownAction()
      let param = {mobile: this.mobile, sendTimes: this.sendTimes,
        busiType: 'copyright', action: 'register'}
      console.log('sendSms() param=',param)
      this.$axios({
        method: 'POST', url: '/common/send-sms', data: param
      }).then(res =>{
        console.log('sendSms() res=',res)
      })
      this.sendTimes++
    },

    async onSubmit (values) {
      this.$axios({
        method: 'POST', url: '/h5common/register', data: values
      }).then(res => {
        console.log('onSubmit res=', res)
        if(res.code != 200){
          this.$toast({
            message: res.message,
            type: 'warning',
            position: 'top'
          })
        } else {
          this.$toast.success('用户注册成功')
          //localStorage.setItem('h5-token', 'Bearer ' + res.data.token) // 存token，后续所有接口都要用!
          this.$router.push({ path: '/login' }) // 路由切换主页layout
        }
      })
      // try {
      //   await this.$axios({ method: 'POST', url: '/user/register', data: values })
      //   this.$toast.success('注册成功')
      //   this.$router.push({ path: '/login' }) // 路由切换login
      // } catch (error) {
      //   this.$toast.fail(error.response.data.message)
      // }
      // console.log(values)

      //await this.$axios({ method: 'POST', url: '/user/register', data: values })
      // console.log(res)
      //this.$toast.success('注册成功')
      //this.$router.push({ path: '/login' }) // 路由切换login
    },
    onClickLeft() {
      console.log('onClickLeft')
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.link {
  color: #069;
  font-size: 12px;
  padding-right: 20px;
  float: right;
}
</style>
