<template>
  <div class="login-page">

    <van-nav-bar title="基础数权"/>

    <van-form @submit="onSubmit">
      <van-field
        v-model="mobile"
        name="mobile"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[{ required: true, message: '请填写手机号码' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="用户密码"
        placeholder="请输入用户密码"
        :rules="[{ required: true, message: '请填写用户密码' }]"
      />
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">确  定</van-button>
      </div>
    </van-form>

    <!-- vant：打破局限，就是工具，用就用，不用就自己写！ -->
    <router-link to="/register" class="rightLink">账号注册</router-link>
    <router-link to="/password" class="leftLink">忘记密码，去重置</router-link>

  </div>
</template>

<script>

export default {
  name: 'login-page',
  data () {
    return {
      mobile: '',
      password: ''
    }
  },
  methods: {
    async onSubmit (values) {
      this.$axios({
        method: 'POST', url: '/h5common/login', data: values
      }).then(res => {
        console.log('onSubmit res=', res)
        if(res.code != 200){
          this.$toast({
                message: res.message,
                type: 'warning',
                position: 'top'
          })
        } else {
          console.log('res.data = ', res.data)
          this.$toast.success('登录成功')
          localStorage.setItem('token', res.data.token) // 存token，后续所有接口都要用!
          localStorage.setItem('role', res.data.role)
          localStorage.setItem('mobile', res.data.mobile)
          localStorage.setItem('nickName', res.data.nickName)
          localStorage.setItem('busiPersonId', res.data.busiPersonId)
          localStorage.setItem('id', res.data.id)
          //this.$router.push({ path: '/article' }) // 路由切换主页layout
          this.$router.push({ path: '/home' }) // 路由切换主页layout
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.leftLink {
  color: #069;
  font-size: 12px;
  padding-left: 20px;
  float: left;
}
.rightLink {
  color: #069;
  font-size: 12px;
  padding-right: 20px;
  float: right;
}
</style>
